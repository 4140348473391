import React from "react";
import "./Header.css";
import logo from "../../../assets/images/Ecomarg-Logo.svg";

function Header() {
  return (
    <header className="header">
      <div className="logo-img">
        <div>
          <img src={logo} alt="Ecomarg Packaging..." className="logo" />
        </div>
        <div>
          <h3>EcoMarg Packaging</h3>
        </div>
      </div>
      <ul className="list">
        <a href="/">
          <li className="list-item">Home</li>
        </a>
        <a href="#about">
          <li className="list-item">About</li>
        </a>
        <a href="#products">
          <li className="list-item">Products</li>
        </a>
        <a href="#contact">
          <li className="list-item">Contact</li>
        </a>
      </ul>
    </header>
  );
}

export default Header;
