import React from "react";
import Header from "./components/common/Header/Header.js";
import Home from "./components/Home/Home.js";
import About from "./components/About/About.js";
import Products from "./components/Products/Products.js";
import Footer from "./components/common/Footer/Footer.js";
import Contact from "./components/Contact/Contact.js";

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Products />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
