import React from "react";
import nature from "../../assets/videos/nature.mp4";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <section className="video-container">
        <video src={nature} autoPlay muted loop />
        <div class="callout">
          <div className="intro">
            Expert packaging services for your business
          </div>
          <div class="desc">
            Our team of professionals provides reliable and cost-effective
            packaging solutions.
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
