import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="social-media">
        <FacebookIcon className="media-icon" />
        <WhatsAppIcon className="media-icon" />
        <InstagramIcon className="media-icon" />
      </div>
      <div className="copywright">&copy; 2023 EcoMarg Packaging By DBhuta</div>
    </footer>
  );
};

export default Footer;
