import React from "react";
import "./Products.css";
import Product1 from "../../assets/images/Product1.jpg";
import Product2 from "../../assets/images/Product2.jpg";
import Product3 from "../../assets/images/Product3.jpg";
import Product4 from "../../assets/images/Product4.jpg";
import Product5 from "../../assets/images/Product5.jpg";
import Product6 from "../../assets/images/Product6.jpg";

const Products = () => {
  return (
    <div className="products" id="products">
      <div className="product-title">EcoMarg Products</div>
      <div className="product-content">
        Explore our range of biodegradable packaging products.
      </div>
      <div className="img-container">
        {/* <img src={Product1} />
        <img src={Product2} />
        <img src={Product3} />
        <img src={Product4} />
        <img src={Product5} />
        <img src={Product6} /> */}
      </div>
    </div>
  );
};

export default Products;
