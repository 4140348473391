import React from "react";
import "./About.css";
import About1 from "../../assets/images/new-logo.png";
import Client from "../../assets/images/client.jpg";

const width = window.screen.width;

const About = () => {
  return (
    <div className="about" id="about">
      <div className="about-title">About EcoMarg Packaging</div>
      <div className="about-content">
        <div className="about-image">
          <img src={About1} className="about-img" />
        </div>
        <div className="about-desc">
          <div className="content-title">Our Story</div>
          <div className="content">
            EcoMarg is a leading packaging company in India that provides
            sustainable and eco-friendly alternatives to traditional plastics.
            Our compostable and biodegradable packaging is made from plant-based
            materials and can help reduce plastic waste and pollution. We offer
            a wide range of packaging solutions for businesses of all sizes,
            including food packaging, retail packaging, and e-commerce
            packaging. Contact us today to learn more about how we can help you
            reduce your environmental impact.
          </div>
        </div>
      </div>
      {width <= 425 ? (
        <div className="about-content">
          <div className="about-image">
            <img src={Client} className="about-img" />
          </div>
          <div className="about-desc">
            <div className="content-title">Our Services</div>
            <div className="content">
              At EcoMarg, we offer a range of services for packaging all types
              of compostable and biodegradable products. Our services include
              customized packaging solutions, biodegradable materials, and
              environmentally friendly packaging options. We understand the
              importance of sustainability and strive to provide eco-friendly
              solutions that are easy on the environment while still protecting
              your products during transportation and storage.
            </div>
          </div>
        </div>
      ) : (
        <div className="about-content">
          <div className="about-desc">
            <div className="content-title">Our Services</div>
            <div className="content">
              At EcoMarg, we offer a range of services for packaging all types
              of compostable and biodegradable products. Our services include
              customized packaging solutions, biodegradable materials, and
              environmentally friendly packaging options. We understand the
              importance of sustainability and strive to provide eco-friendly
              solutions that are easy on the environment while still protecting
              your products during transportation and storage.
            </div>
          </div>
          <div className="about-image">
            <img src={Client} className="about-img" />
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
