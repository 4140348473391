import React from "react";
import "./Contact.css";
// import newLogo from "../../assets/images/Ecomarg-Logo.svg";

const Contact = () => {
  return (
    <div className="contact" id="contact">
      <div className="contact-title">Contact</div>
      <div className="contact-content">
        <div className="contact-details">
          {/* <div className="img-container">
            <img src={newLogo} />
          </div> */}
          <div className="contact-item">Contact Number: +91 9429292323 </div>
          <div className="contact-item">Email: contactecomarg@gmail.com</div>
          <div className="contact-item">
            Address: B1-36 Satyen Society near Surmaya Flats b/h utkarsh petrol
            pump karelibaugh Vadodara-390018
          </div>
        </div>
        <div className="contact-form">
          <form>
            <div>
              <div className="label-title">Full Name</div>
              <input placeholder="Enter your Full Name" />
            </div>
            <div className="input-label">
              <div className="label-title">Email</div>
              <input placeholder="Enter your Email" />
            </div>
            <div className="input-label">
              <div className="label-title">Contact</div>
              <input type="number" />
            </div>
            <div className="input-label">
              <div className="label-title">Mention Your Requirement</div>
              <textarea placeholder="Let us know your requirements" />
            </div>
            <div className="input-label">
              <button>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
